const loginRedirectMixin = {
  methods: {
    loginRedirect: function(response) {
      // Send to payment route
      if (response.data.return_url) {
        window.location = response.data.return_url;
      }
    }
  }
};

export default loginRedirectMixin;
