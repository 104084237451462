<template>
  <div class="SAML-login">
    <Alert :dismiss-secs="alertTime" :show-alert="showAlert" :alert-message="alertMessage"
           :alert-variant="'danger'" />
    <b-container>
      <b-form v-show="!showLoading" id="login-form" accept-charset="UTF-8">
        <b-row>
          <b-col>
            Log in using SAML SSO
            <b-form-group
              label="Email Address"
              :class="{ 'text-danger': errors.has('email') }"
              label-size="sm"
              label-for="email">
              <b-form-input
                id="email"
                key="email"
                v-model="form.email" v-validate="'required|email'"
                data-vv-validate-on="blur|change"
                :class="{ 'is-invalid': errors.has('email') }"
                class="form-control"
                type="text"
                name="Email" />
              <b-row class="stay-thick">
                <b-col id="error-field">
                  <span id="first-name-errors" class="small red-text">{{ errors.first('Email') }}</span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="text-center">
              <b-button
                id="login-submit"
                type="submit"
                variant="primary"
                name="loginSubmit"
                @click="submit">
                Log in
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-link :to="{ name: 'Login', query: $route.query }">
              <small>Log in using a password or social account</small>
            </b-link>
          </b-col>
        </b-row>
      </b-form>
      <div v-show="showLoading">
        <h3 class="text-center m-5">
          loading...
          <sp-loader />
        </h3>
      </div>
    </b-container>
  </div>
</template>

<script>
import redirectMixin from '@/utils/mixins/Redirect';
import loginRedirectMixin from '@/utils/mixins/LoginRedirect';
import SpLoader from '@/components/SPLoader';

export default {
  name: 'SAML',
  components: {
    'sp-loader': SpLoader,
    Alert: () => import('@/components/Elements/Alert.vue'),
  },
  mixins: [loginRedirectMixin, redirectMixin],
  data() {
    return {
      form: {
        email: ''
      },
      showLoading: false,
      SAMLProvider: null,
      dismissCountDown: 0,
      showAlert: false,
      alertTime: 120,
      alertMessage: null
    };
  },
  created() {
    // Check for error cookie from Cognito logout
    if (this.$cookies && this.$cookies.get('SP_SAML_Error')) {
      this.showLoading = false;
      this.showError((this.$cookies.get('SP_SAML_Error')).replace(/\+/g, ' '));
      this.$cookies.set('SP_SAML_Error', '', 0, '/', 'solidprofessor.com');
    }
    // Check for error passed from API
    if (this.$route.query.error) {
      this.showLoading = false;
      this.showError(this.$route.query.error);
    }
    // If email, set it in the data
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
    }
    // If Cognito token process it
    if (this.$route.query.code) {
      this.showLoading = true;
      let redirectUrl = this.processSAMLReturn();
      this.redirectUrl(redirectUrl);
    }
    // Always check for cached user
    if (!this.user) {
      this.checkForExistingUser();
    }
  },
  methods: {
    /**
     * Runs validator on form Email then
     * IF valid email -> gets SAML info from API for email domain
     * IF API response is valid -> encodes current app state, builds redirect URI, and redirects to third party SAML auth
     *
     * On Error -> custom or default error message
     * @param event -- triggering event
     * @return {void} -- sets window to new location
     */
    submit: function(event) {
      event.preventDefault();
      this.$validator.validateAll().then( (validated) => {
        if (validated) {
          let provider = this.form.email.trim().split('@').pop();
          this.$store.dispatch('plans/getSAMLProvider', provider).then((response) => {
            if (response && response.data && response.data.message && response.data.message === 'success') {
              let enc_state = this.buildEncodedState(response.data.data.provider, this.$route.query.referrer);
              const url = this.buildRedirectURI(
                response.data.data.cognito_domain,
                response.data.data.idp_identifier,
                response.data.data.client_id,
                enc_state,
                response.data.data.redirect_uri
              );
              window.location = url;
            } else {
              this.showAlert = !this.showAlert;
              this.alertMessage = response.data.message ? response.data.message: 'An error occured. Please try again';
            }
          });
        }
      });
    },
    /**
     * Takes current state object (currently just 'provider' and 'referrer': aka 'return_url', if it exists) stringifies
     * it and then base64 encodes it.
     * @param {string} provider -- the SAML provider name
     * @param {string} referrer -- optional, aka 'return_url'. However, 'return_url' is used by Cognito, so considering
     * it reserved
     * @returns {string} base64 encoded
     */
    buildEncodedState(provider, referrer=null) {
      let state = {'provider': provider};
      if (referrer) {
        state['referrer'] = encodeURI(referrer);
      }
      return btoa(JSON.stringify(state));
    },
    /**
     * Simple array join to build all the different parts of a url string
     * @param {string} cognito_domain -- supplied by API provider record
     * @param {string} idp_identifier -- supplied by API provider record
     * @param {string} client_id -- supplied by API provider record
     * @param {string} enc_state -- base64 encoded string
     * @param {string} redirect_uri -- The cognito redirect url. MUST match hardcoded value for individual Cognito client
     * @returns {string} -- fully qualified URL
     */
    buildRedirectURI(cognito_domain, idp_identifier, client_id, enc_state, redirect_uri) {
      return [
        cognito_domain,
        '/authorize?idp_identifier=',
        idp_identifier,
        '&response_type=code&client_id=',
        client_id,
        '&state=',
        enc_state,
        '&redirect_uri=',
        redirect_uri
      ].join('');
    },
    /**
     * Sets error message and shows error
     * @param {string} errorMessage
     */
    showError(errorMessage) {
      this.alertMessage = errorMessage;
      this.showAlert = !this.showAlert;
    },
    /**
     *  Simple array join to build all the different parts of a url string
     *  @param {string} cognito_domain -- supplied by API provider record
     *  @param {string} client_id -- supplied by API provider record
     *  @param {string} redirect_uri -- The cognito redirect url. MUST match hardcoded value for individual Cognito client
     *  @returns {string} -- fully qualified URL
     */
    buildLogoutURI(cognito_domain, client_id, redirect_uri) {
      return [ cognito_domain, '/logout?client_id=', client_id, '&logout_uri=', redirect_uri].join('');
    },
    /**
     * Checks for cached user credentials and logs in if exists
     *
     * @return {void} -- full redirect
     */
    checkForExistingUser() {
      this.$store.dispatch('user/loadUserInfo').then(() => {
        if (this.isLoggedIn) {
          this.redirectUrl(this.$route.query.return_url ? this.$route.query.return_url: this.SPENV.APP_URL);
        }
      })
        .catch(
          (error) => {
            this.showAlert = !this.showAlert;
            this.alertMessage = error.data.message ? error.data.message: 'An error occured. Please try again';
          });
    },
    /**
     * A successful third party Login will redirect back to SP with auth token and encoded state. The token and state are
     * decoded from the url and processed into a url to be sent to the Accounts API.
     * @returns {string}
     */
    processSAMLReturn() {
      let redirectUrl = null;
      let decodedState = this.$route.query.state ? JSON.parse(atob(this.$route.query.state)): null;
      if (decodedState) {
        redirectUrl = `${this.SPENV.ACCOUNTS_API_URL}/oauth/saml?token=${this.$route.query.code}&provider=${decodedState.provider}`;
        if (decodedState.referrer) {
          redirectUrl = `${redirectUrl}&referrer=${decodedState.referrer}`;
        }
      } else {
        redirectUrl = `${this.SPENV.ACCOUNTS_API_URL}/oauth/saml`;
      }
      return redirectUrl;
    }
  },
};
</script>

<style lang="scss" scoped>
.SAML-login {
  .stay-thick {
    height: 20px;
  }
}
</style>
